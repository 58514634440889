import React, { MouseEvent } from 'react';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const FilterIcon = ({ className, onClick }: Props) => {
  const { themeName } = useThemeContext();
  const isExpTheme = themeName && themeName !== ThemeNames.ZOOCASA;
  
  return (
    <>
      {!isExpTheme ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24" className={className} onClick={onClick}>
          <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g fill="#191B1E" fillRule="nonzero" transform="translate(-330 -26)">
              <g transform="rotate(90 163 189)">
                <path d="M22.355 2.59H8.97A2.766 2.766 0 006.352.707c-1.216 0-2.25.79-2.618 1.883h-2.09a.879.879 0 000 1.758h2.09A2.766 2.766 0 006.352 6.23c1.215 0 2.25-.79 2.617-1.882h13.387a.879.879 0 100-1.758zM6.352 4.473a1.005 1.005 0 010-2.008 1.005 1.005 0 010 2.008zM22.355 10.121h-2.09a2.766 2.766 0 00-2.617-1.883c-1.215 0-2.249.79-2.617 1.883H1.645a.879.879 0 000 1.758H15.03a2.766 2.766 0 002.617 1.883c1.216 0 2.25-.79 2.618-1.883h2.09a.879.879 0 100-1.758zm-4.707 1.883a1.005 1.005 0 010-2.008 1.005 1.005 0 010 2.008zM22.355 17.652h-9.62a2.766 2.766 0 00-2.618-1.882c-1.215 0-2.25.79-2.617 1.882H1.645a.879.879 0 000 1.758H7.5a2.766 2.766 0 002.617 1.883c1.216 0 2.25-.79 2.618-1.883h9.62a.879.879 0 100-1.758zm-12.238 1.883a1.005 1.005 0 010-2.008 1.005 1.005 0 010 2.008z"></path>
              </g>
            </g>
          </g>
        </svg>
        
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" className={className} onClick={onClick}>
          <path d="M2.5 14.1667V15.8333H7.5V14.1667H2.5ZM2.5 4.16667V5.83333H10.8333V4.16667H2.5ZM10.8333 17.5V15.8333H17.5V14.1667H10.8333V12.5H9.16667V17.5H10.8333ZM5.83333 7.5V9.16667H2.5V10.8333H5.83333V12.5H7.5V7.5H5.83333ZM17.5 10.8333V9.16667H9.16667V10.8333H17.5ZM12.5 7.5H14.1667V5.83333H17.5V4.16667H14.1667V2.5H12.5V7.5Z"/>
        </svg>
      )}
    </>
  );
};

export default FilterIcon;
